<template>
  <div class="statistics-container">
    <v-title>
      <Breadcrumb>
        <Breadcrumb-item>
          <i class="icon-home"></i>评估问卷</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <section class="content-panel">
      <div class="dashboard">
        <div class="dashboard-item">
          <div class="dashboard-header">
            <p class="dashboard-item-title">问卷填写人数(本周)</p>
            <p class="dashboard-item-subtitle">Number of respondents</p>
          </div>
        </div>
        <div class="dashboard-item">
          <div class="dashboard-header">
            <p class="dashboard-item-title">健康问卷调研情况(本月)</p>
            <p class="dashboard-item-subtitle">Health questionnaire survey</p>
          </div>
        </div>
        <div class="dashboard-item">
          <div class="dashboard-header">
            <p class="dashboard-item-title">填写用户人群分析</p>
            <p class="dashboard-item-subtitle">Population analysis</p>
          </div>
        </div>
        <div class="dashboard-item">
          <div class="dashboard-header">
            <p class="dashboard-item-title">人群年龄比重</p>
            <p class="dashboard-item-subtitle">Age proportion of population</p>
          </div>
        </div>
      </div>
      <div class="search-bar">
        <Form :model="form" :label-width="80" inline>
          <FormItem label="问卷类型">
            <Select v-model="form.type" style="width:200px">
              <Option>ABO分型调查问卷</Option>
              <Option>健康资源盘点问卷</Option>
            </Select>
          </FormItem>
          <FormItem label="填写时间">
            <DatePicker :value="form.start_date" type="date" placeholder="选择日期" style="width: 200px"></DatePicker>
            <DatePicker :value="form.end_date" type="date" placeholder="选择日期" style="width: 200px"></DatePicker>
          </FormItem>
          <FormItem label="状态">
            <Select v-model="form.status" style="width:100px">
              <Option>已交付</Option>
              <Option>未交付</Option>
            </Select>
          </FormItem>
          <FormItem label="姓名搜索">
            <Input v-model="form.name" style="width: 100px;margin-right:15px;" />
            <Button type="primary">搜索</Button>
            <Button type="success">填写问卷</Button>
          </FormItem>
        </Form>
      </div>
      <div class="result-panel">
        <Table :columns="columns" :data="data"></Table>
      </div>
    </section>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';

export default {
	components: {
		'v-title': vTitle,
	},
	data() {
		return {
			form: {
				type: '',
				date: '',
				status: '',
				name: '',
			},
			columns: [
				{
					title: '问卷名称',
					key: 'name',
					align: 'center',
					render: (h, params) => {
						return h(
							'span',
							{
								style: {
									color: '#4caf50',
								},
							},
							params.row.name,
						);
					},
				},
				{
					title: '填写时间',
					key: 'name',
					align: 'center',
				},
				{
					title: '顾客名称',
					key: 'name',
					align: 'center',
					render: (h, params) => {
						return h('a', [
							h(
								'a',
								{
									style: {
										color: '#ff8400',
									},
									attrs: {
										target: '_blank',
										href:
											'#/base/member/detail/' +
											this.boostList[params.index].member_id +
											'/' +
											2 +
											'/' +
											1,
									},
								},
								params.row.name,
							),
						]);
					},
				},
				{
					title: '性别',
					key: 'name',
					align: 'center',
				},
				{
					title: '年龄',
					key: 'name',
					align: 'center',
				},
				{
					title: '问题数量',
					key: 'name',
					align: 'center',
				},
				{
					title: '报告',
					key: 'name',
					align: 'center',
					render: (h, params) => {
						return h('a', [
							h(
								'a',
								{
									style: {
										color: '#ff8400',
									},
									attrs: {
										target: '_blank',
										href:
											'#/base/member/detail/' +
											this.boostList[params.index].member_id +
											'/' +
											2 +
											'/' +
											1,
									},
								},
								params.row.member_name,
							),
						]);
					},
				},
				{
					title: '状态',
					key: 'name',
					align: 'center',
					render: (h, params) => {
						return h('a', [
							h(
								'a',
								{
									style: {
										color: '#ff8400',
									},
									attrs: {
										target: '_blank',
										href:
											'#/base/member/detail/' +
											this.boostList[params.index].member_id +
											'/' +
											2 +
											'/' +
											1,
									},
								},
								params.row.member_name,
							),
						]);
					},
				},
				{
					title: '操作',
					key: 'name',
					align: 'center',
					render: (h, params) => {
						return h('div', [
							h(
								'a',
								{
									on: {
										click: () => {
											this.$router.push({
												name: 'FoodRecordList',
												params: { food_id: this.foodList[params.index].id },
											});
										},
									},
								},
								'查看',
							),
							h(
								'a',
								{
									style: {
										marginLeft: '20px',
										color: '#fdad00',
									},
									on: {
										click: () => {
											sessionStorage.setItem('food_id', this.foodList[params.index].id);
											sessionStorage.setItem('foodDate', this.foodList[params.index].create_time);
											this.$router.push({ name: 'FoodRecordAdd', params: { type_id: 0 } });
										},
									},
								},
								'下载',
							),
						]);
					},
				},
			],
			data: [
				{
					name: 'John Brown',
					age: 18,
					address: 'New York No. 1 Lake Park',
					date: '2016-10-03',
				},
			],
		};
	},
};
</script>

<style lang="less" scoped>
.content-panel {
  padding: 15px;
  .dashboard {
    display: flex;
    justify-content: space-between;
    &-item {
      width: 24.5%;
      height: 120px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: #ccc 0 0 4px 0;
      padding: 10px;
      &-title {
        font-size: 16px;
        font-weight: 700;
      }
      &-subtitle {
        color: #bbb;
      }
    }
  }
  .search-bar {
    margin-top: 20px;
    background-color: #fff;
    padding: 24px 24px 0;
    border-radius: 4px;
  }
  .result-panel {
    background-color: #fff;
    margin-top: 20px;
    padding: 15px;
    border-radius: 4px;
  }
}
</style>
